
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexxQmFkRgxhYMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/account/index.vue?macro=true";
import { default as accountFQaf9bt59uMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/account.vue?macro=true";
import { default as indexzhz4ZG2DZ2Meta } from "/home/bgrand/Documents/Projects/live-display/app/pages/assets/index.vue?macro=true";
import { default as assetsdQRHlDgrD2Meta } from "/home/bgrand/Documents/Projects/live-display/app/pages/assets.vue?macro=true";
import { default as indexG5rICNiZ4kMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/index.vue?macro=true";
import { default as indexDWXOC2ENZDMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/invoices/index.vue?macro=true";
import { default as invoicesvWNKHFFEpPMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/invoices.vue?macro=true";
import { default as _91pageId_931hqiBpGbLIMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId]/[pageId].vue?macro=true";
import { default as indexefjmIBZiqmMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId]/index.vue?macro=true";
import { default as _91playlistId_93nq4ybdkQYsMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId].vue?macro=true";
import { default as indexnYon9n1r1pMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists/index.vue?macro=true";
import { default as playlistsNtyxcGAvnxMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists.vue?macro=true";
import { default as _91screenId_932dXT4dPE83Meta } from "/home/bgrand/Documents/Projects/live-display/app/pages/screens/[screenId].vue?macro=true";
import { default as index6W8P0NlV40Meta } from "/home/bgrand/Documents/Projects/live-display/app/pages/screens/index.vue?macro=true";
import { default as screensk68Sa3Xfj2Meta } from "/home/bgrand/Documents/Projects/live-display/app/pages/screens.vue?macro=true";
import { default as sign_45inoyobORS7QgMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/sign-in.vue?macro=true";
import { default as sign_45upGl3ijpheTGMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/sign-up.vue?macro=true";
import { default as support5QkHwITpWkMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/support.vue?macro=true";
import { default as _91userId_93kuwZHaZePAMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/users/[userId].vue?macro=true";
import { default as indexQFgSUvus5wMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/users/index.vue?macro=true";
import { default as usersKFBuFUnVo7Meta } from "/home/bgrand/Documents/Projects/live-display/app/pages/users.vue?macro=true";
export default [
  {
    name: accountFQaf9bt59uMeta?.name,
    path: "/account",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/account.vue"),
    children: [
  {
    name: "account",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/account/index.vue")
  }
]
  },
  {
    name: assetsdQRHlDgrD2Meta?.name,
    path: "/assets",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/assets.vue"),
    children: [
  {
    name: "assets",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/assets/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexG5rICNiZ4kMeta || {},
    redirect: "/playlists",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/index.vue")
  },
  {
    name: invoicesvWNKHFFEpPMeta?.name,
    path: "/invoices",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/invoices.vue"),
    children: [
  {
    name: "invoices",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/invoices/index.vue")
  }
]
  },
  {
    name: playlistsNtyxcGAvnxMeta?.name,
    path: "/playlists",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists.vue"),
    children: [
  {
    name: _91playlistId_93nq4ybdkQYsMeta?.name,
    path: ":playlistId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId].vue"),
    children: [
  {
    name: "playlists-playlistId-pageId",
    path: ":pageId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId]/[pageId].vue")
  },
  {
    name: "playlists-playlistId",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId]/index.vue")
  }
]
  },
  {
    name: "playlists",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists/index.vue")
  }
]
  },
  {
    name: screensk68Sa3Xfj2Meta?.name,
    path: "/screens",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/screens.vue"),
    children: [
  {
    name: "screens-screenId",
    path: ":screenId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/screens/[screenId].vue")
  },
  {
    name: "screens",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/screens/index.vue")
  }
]
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inoyobORS7QgMeta || {},
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upGl3ijpheTGMeta || {},
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/sign-up.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/support.vue")
  },
  {
    name: usersKFBuFUnVo7Meta?.name,
    path: "/users",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/users.vue"),
    children: [
  {
    name: "users-userId",
    path: ":userId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/users/[userId].vue")
  },
  {
    name: "users",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/users/index.vue")
  }
]
  }
]