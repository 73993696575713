export default defineNuxtRouteMiddleware(to => {
  const authenticationStore = useAuthenticationStore()
  const { isAuthenticated } = storeToRefs(authenticationStore)

  const authUserStore = useAuthUserStore()
  const { currentAuthUser } = storeToRefs(authUserStore)

  const authUserId = currentAuthUser.value.initial.id

  // Public page
  if (isSamePath(to, '/support')) {
    return
  }

  // User not authenticated
  if (
    !isAuthenticated.value &&
    !isSamePath(to, '/sign-in')
  ) {
    return navigateTo('/sign-in')
  }

  // User not registered
  if (
    isAuthenticated.value &&
    !authUserId &&
    !isSamePath(to, '/sign-up')
  ) {
    return navigateTo('/sign-up')
  }

  const isSamePaths = (
    isSamePath(to, '/sign-in') ||
    isSamePath(to, '/sign-up')
  )

  // User authenticated and registered
  if (
    isAuthenticated.value &&
    authUserId &&
    isSamePaths
  ) {
    return navigateTo('/playlists')
  }
})
