import { StorageSerializers } from '@vueuse/core'
import { hasUserRoles } from '../utils/user'

import type { DocumentData } from 'firebase/firestore'
import type { UserData } from 'shared-types/user'
import type { Data } from 'shared-types/data'
import type { UseStorageOptions } from '@vueuse/core'

const storeName = 'authUser'
const collectionName = 'users'

const localStorageOpts: UseStorageOptions<any> = {
  serializer: StorageSerializers.object
}

export const useAuthUserStore = defineStore(storeName, () => {
  const authenticationStore = useAuthenticationStore()
  const { isAuthenticated, authUser } = storeToRefs(authenticationStore)

  const enterpriseId = computed(() => {
    return authUser.value?.claims?.enterpriseId || ''
  })

  const nuxtApp = useNuxtApp()
  const firestore = nuxtApp.$firebase.firestore
  const database = shallowRef(firestore)

  const localUsers = useLocalStorage<UserData[]|null>(storeName, null, localStorageOpts)

  const isLoading = ref(false)
  const currentAuthUser = reactive<Data<UserData>>({
    initial: getInitialUserData(),
    newest: getInitialUserData()
  })
  const users = ref<UserData[]>([])
  const totalUsers = ref<number|null>(null)
  const lastUserDoc = ref<DocumentData|null>(null)

  const hasAuthUserChanges = computed(() => {
    const rawCurrUser = toDeepRaw(currentAuthUser)
    const strInitialUser = JSON.stringify(rawCurrUser.initial)
    const strNewestUser = JSON.stringify(rawCurrUser.newest)

    return strInitialUser !== strNewestUser
  })

  const authUserByRole = computed(() => {
    const userRoles = currentAuthUser.initial.roles
    const isAdminUser = hasUserRoles(userRoles, ['administrator'])

    return {
      isAdministrator: isAdminUser,
      isAccountant: isAdminUser || hasUserRoles(userRoles, ['accountant']),
      isManager: isAdminUser || hasUserRoles(userRoles, ['manager']),
      isCreator: isAdminUser || hasUserRoles(userRoles, ['creator']),
      isUploader: isAdminUser ||hasUserRoles(userRoles, ['uploader'])
    }
  })

  const { initStore, getDoc, updateDoc, resetStore } = getStoreActions<UserData>({
    collectionName,
    state: {
      isLoading,
      isAuthenticated,
      database,
      currentDocument: currentAuthUser,
      documents: users,
      localDocuments: localUsers,
      totalDocuments: totalUsers,
      lastDocument: lastUserDoc
    },
    getInitialData: getInitialUserData,
    documentFilter: () => ({
      where: [
        {
          fieldName: 'enterpriseId',
          condition: '==',
          value: enterpriseId.value
        }
      ]
    })
  })

  return {
    isLoading,
    currentAuthUser,
    hasAuthUserChanges,
    authUserByRole,
    initAuthUser: initStore,
    getAuthUser: getDoc,
    updateAuthUser: updateDoc,
    resetAuthUser: resetStore
  }
})
