import type { UserRole } from 'shared-types/user'

export function getUserRoleTranslation (language: 'fr', role: UserRole) {
  const translation = {
    'fr': {
      administrator: 'Administrateur',
      accountant: 'Comptable',
      manager: 'Responsable',
      creator: 'Créateur',
      uploader: 'Graphiste'
    }
  }

  return translation?.[language]?.[role] || ''
}

export function hasUserRoles (userRoles: UserRole[], requiredRoles: UserRole[]): boolean {
  return (
    userRoles.includes('administrator') ||
    requiredRoles.every(requiredRole => userRoles.includes(requiredRole))
  )
}
